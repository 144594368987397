import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/organisms/layout"
import HouseDetailsSecond from "../../components/molecules/HouseDetailsSecond/HouseDetailsSecond"
import GoBackButton from "../../components/molecules/goBackButton"
import VizImage from "../../components/molecules/HouseDetailsSecond/VizImage/VizImage"
import InteractiveVisualization from "../../components/molecules/InteractiveVisualization/InteractiveVisualization"
import AOS from "aos"
import "aos/dist/aos.css"

const HouseLayout = ({ data }) => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])
  return (
    <Layout pageNumber={2}>
      <GoBackButton isAnotherPage={true} houseQuery={data.datoCmsHouse.number} />
      <HouseDetailsSecond query={data.datoCmsHouse} isAnotherPage="Osiedle Nove Chruściele" />
      <VizImage
        imageSrc={data.datoCmsHouse.threedVis.gatsbyImageData}
        mapSrc={data.datoCmsHouse.twodVis.gatsbyImageData}
        tableData={data.datoCmsHouse.firstFloor}
      />
      <InteractiveVisualization />
    </Layout>
  )
}

export const query = graphql`
  query HouseTemplate($number: String!) {
    datoCmsHouse(number: { eq: $number }) {
      area
      availability
      deliveryDate
      garage
      number
      parcelArea
      price
      firstFloor {
        area
        name
        roomNumber
      }
      threedVis {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      twodVis {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default HouseLayout
