import React, { useState } from "react"
import styled from "styled-components"
import TextHeader from "../../../atoms/textHeader"
import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

const VizImageBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
`

const VizDataContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 350px;
  margin-bottom: 50px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1279px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const VizImg = styled(GatsbyImage)`
  height: auto;
  width: 100%;
  max-width: 800px;
  //aspect-ratio: 1;

  &.isTwoD {
    width: 70%;
  }
`

const VizTableBox = styled.div`
  min-width: 370px;
  margin-left: 15px;
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 600px;
    margin-left: 0;
    margin-top: 15px;
  }
`

const VizTableHeader = styled.p`
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: white;
  display: block;
  padding: 10px;
  font-weight: bold;
`

const VizTableSum = styled(VizTableHeader)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const VizHeader = styled(TextHeader)`
  font-weight: 100;
`

const DataList = styled.ul`
  &:nth-of-type(1) {
    border-top: 1px solid black;
  }
`

const ListEl = styled.li`
  list-style: decimal;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
`

const ImgContainer = styled.div`
  height: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Buttons = styled.div`
  display: flex;
  margin: 30px 0;
`

const StyledButton = styled.button`
  font-family: "Red Hat Display", sans-serif;
  width: 90px;
  height: 45px;
  font-size: ${({ theme }) => theme.fontSize.medium};
  border-radius: 50px;
  border: 1px solid #000000;
  cursor: pointer;

  &:first-child {
    margin-right: 30px;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`

const VizImage = ({ imageSrc, mapSrc, tableData }) => {
  const [currentVis, setCurrentVis] = useState(1)
  return (
    <VizImageBox>
      <VizHeader>Wizualizacja pomieszczeń</VizHeader>
      <VizDataContainer>
        <ImgContainer>
          {currentVis === 1 ? (
            <VizImg
              image={imageSrc ? imageSrc : ""}
              alt="Wizualizacja 3D"
              //   fluid={imageSrc ? imageSrc : ""}
              //   alt="2D vizualization"
              //   imgStyle={{ objectFit: "contain" }}
            />
          ) : (
            <VizImg
              image={mapSrc ? mapSrc : ""}
              alt="Rzut 2D"
              className="isTwoD"
              //   fluid={imageSrc ? imageSrc : ""}
              //   alt="2D vizualization"
              //   imgStyle={{ objectFit: "contain" }}
            />
          )}
          <Buttons>
            <StyledButton className={currentVis === 1 && "active"} onClick={() => setCurrentVis(1)}>
              Rzut 3D
            </StyledButton>
            <StyledButton className={currentVis === 2 && "active"} onClick={() => setCurrentVis(2)}>
              Rzut 2D
            </StyledButton>
          </Buttons>
        </ImgContainer>
        <VizTableBox>
          <VizTableHeader>POWIERZCHNIA UŻYTKOWA</VizTableHeader>
          <DataList>
            {tableData
              ? tableData.map((el, index) => (
                  <ListEl key={index}>
                    <span style={{ textTransform: "uppercase" }}>
                      {index + 1}. {el.name}
                    </span>
                    <span>
                      {el.area} m<sup>2</sup>
                    </span>
                  </ListEl>
                ))
              : ""}
          </DataList>
          <VizTableSum>
            <span>RAZEM</span>
            <span>
              {tableData
                ? tableData
                    .map((el) => el.area)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : ""}{" "}
              m<sup>2</sup>
            </span>
          </VizTableSum>
        </VizTableBox>
      </VizDataContainer>
    </VizImageBox>
  )
}

export default VizImage
