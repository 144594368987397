export const handleColorType = (color) => {
  switch (color) {
    case "Dostępny":
      return "#21c5c6"
    case "Zarezerwowany":
      return "#14172a"
    case "Sprzedany":
      return "#c4c4c4"
    default:
      return "#21c5c6"
  }
}
