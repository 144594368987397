import React from "react"
import styled from "styled-components"
import ArrowLeft from "../../assets/images/Icons/arrows/icon-arrowLeft.svg"
import { Link, navigate } from "gatsby"
import Button from "../atoms/button"

const ButtonBox = styled.div`
  width: 70vw;
  min-width: 350px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 0.4px solid grey;
  @media (max-width: 991px) {
    flex-direction: column;
    border: none;
  }
`

const ButtonLink = styled(Link)`
  padding: 20px 90px;
  border-radius: 90px;
  border: 1px solid black;
  text-decoration: none;
  color: black;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  text-align: center;
  &:hover {
    background-color: #f2f2f2;
  }
  @media (max-width: 991px) {
    width: 50%;
    min-width: 180px;
  }
`

const ButtonPrint = styled(Button)`
  margin: 0;
  @media (max-width: 991px) {
    margin-top: 10px;
    width: 60%;
    text-align: center;
    min-width: 250px;
  }
`

const Icon = styled.img`
  width: 50px;
  position: absolute;
  top: 6px;
  left: 40px;
`

const GoBackButton = ({ isAnotherPage, houseQuery }) => {
  return (
    <ButtonBox>
      <ButtonLink to={isAnotherPage ? "/2#offert" : "/1#offert"} state={{ loading: false }}>
        <Icon src={ArrowLeft} alt="arrow left" />
        Powrót
      </ButtonLink>
      {isAnotherPage ? (
        <ButtonPrint
          href={`/dom${houseQuery}.pdf`}
          color="black"
          // onClick={() => {
          //   navigate(`/dom${houseQuery}.pdf`)
          // }}
        >
          Wydrukuj PDF
        </ButtonPrint>
      ) : (
        <ButtonPrint
          color="black"
          onClick={() => {
            typeof window !== "undefined" ? window.print() : ""
          }}
        >
          Wydrukuj PDF
        </ButtonPrint>
      )}
    </ButtonBox>
  )
}

export default GoBackButton
