import React from "react"
import styled from "styled-components"
import { handleColorType } from "../../atoms/handleColorType"
import HouseInfoHorizontalSecond from "./HouseInfoHorizontalSecond/HouseInfoHorizontalSecond"
import HouseContactBoxSecond from "./HouseContactBoxSecond/HouseContactBoxSecond"

const HouseDetailsBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;
  @media (max-width: 991px) {
    width: 90vw;
  }
`

const HouseDetailsHeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`

const HouseHeader = styled.h2`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  position: relative;
  width: 100%;
  margin: 0;
`

const HeaderTextBox = styled.div`
  position: relative;
  &::after {
    content: "";
    width: 100px;
    height: 2px;
    background-color: black;
    position: absolute;
    transition: 0.2s ease;
    bottom: -15px;
    left: 0;
  }
`

const StatusBox = styled.div`
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`
const AvaliableSpan = styled.span`
  margin-left: 5px;

  &.available {
    color: #21c5c6;
  }

  &.reserved {
    color: #cc8500;
  }

  &.unavailable {
    color: #e60000;
  }
`

const HouseDetailsSecond = ({ query, isAnotherPage }) => {
  const getClassName = (availability) => {
    switch (availability) {
      case "Dostępne":
        return "available"
      case "Zarezerwowane":
        return "reserved"
      case "Niedostępne":
        return "unavailable"
    }
  }
  return (
    <HouseDetailsBox data-aos="fade-right" data-aos-duration="800">
      <HouseDetailsHeaderBox>
        <HeaderTextBox>
          <HouseHeader style={{ color: "black" }}>{isAnotherPage ? isAnotherPage : `Osiedle "Barany"`}</HouseHeader>
          <HouseHeader style={{ fontSize: `1.7rem` }}>DOM NR. {query.number}</HouseHeader>
        </HeaderTextBox>
        <StatusBox>
          <HouseHeader style={{ color: "black", fontWeight: 100 }}>
            Status:
            <AvaliableSpan avaliablity={query.availability} className={getClassName(query.availability)}>
              {query.availability === "Dostepne" ? "Dostępny" : query.availability}
            </AvaliableSpan>
          </HouseHeader>
        </StatusBox>
      </HouseDetailsHeaderBox>
      <HouseInfoHorizontalSecond query={query} />
      <HouseContactBoxSecond />
    </HouseDetailsBox>
  )
}

export default HouseDetailsSecond
