import React from "react"
import styled from "styled-components"
import ParagraphSmall from "../../../atoms/paragraphSmall"
import Link from "../../../atoms/link"
import TextHeader from "../../../atoms/textHeader"

const HouseContactContainer = styled.div`
  width: 40%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px;
  @media (max-width: 1279px) {
    width: 100%;
    margin-top: 10px;
  }
`

const HouseContactBoxSecond = () => {
  return (
    <HouseContactContainer>
      <div>
        <ParagraphSmall style={{ marginBottom: 0 }}>Masz pytania? Zapraszamy do kontaktu</ParagraphSmall>
        <TextHeader style={{ margin: 0, fontSize: `2rem` }}>+48 888 088 888</TextHeader>
        <ParagraphSmall style={{ marginBottom: 0 }}>Adres e-mail:</ParagraphSmall>
        <Link href="mailto:marcin@domset.pl" color="#21c5c6">
          marcin@domset.pl
        </Link>
      </div>
    </HouseContactContainer>
  )
}

export default HouseContactBoxSecond
